@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DMSans";
  background-color: #ffffff;
}
@font-face {
  font-family: "DMSans";
  src: url("./fonts/DMSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DMSans Bold";
  src: url("./fonts/DMSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "DMSans Italic";
  src: url("./fonts/DMSans-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Game";
  src: url("./fonts/PressStart2P-Regular.ttf") format("truetype");
  font-display: swap;
}
.custom-bg {
  background: linear-gradient(315deg, #1d1d1d 0%, #2c2a2a 100%);
  background-repeat: no-repeat;
}

.custom-linear-bg {
  background-image: linear-gradient(to right, #0e0e0e, #2c2a2a);
}
.shadow-white {
  font-weight: bold;
  opacity: 1 !important;
  text-shadow: 10px 10px 10px 10px #ffffff !important;
}

.ss {
  /* box-shadow: 20px 4px 120px 20px rgba(0, 0, 0, 0.18); */
}

.gradient-button {
  text-transform: uppercase;
  outline: none;
  border: none;
  background: linear-gradient(to right, #ff409a, #c438e5);
  padding: 7px 20px;
  border-radius: 100px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0px 5px 34px rgba(186, 19, 88, 0.42);
  white-space: nowrap;
}

.grid-cols-24 {
  grid-template-columns: repeat(24, minmax(0, 1fr));
}

.custom-bg {
  background: url("./assets/svg/bg.svg");
  background-color: #201d47;
  background-size: cover;
  background-repeat: no-repeat;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.game {
  font-family: "Game";
}

.grad {
  background: url("../src/assets/svg/grad.svg");
  background-size: cover;
  background-position-x: 0px;
  background-position-y: -100px;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
}

.dot {
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

.input:checked ~ .dot {
  transform: translateX(100%);
}

/* Define the keyframes animation */
@keyframes vibrate {
  0% {
    transform: rotateX(0deg);
  }
  89% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(10deg);
  }
  92% {
    transform: rotate(-10deg);
  }
  97% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Apply the animation to the button */
.vibrating-button {
  animation: vibrate 10s infinite;
  transition: transform 0.3s ease-in-out;
}

/* Stop the animation on hover */
.vibrating-button:hover {
  animation: none;
  transform: translateX(0) translateY(0);
}

/* Target the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #cfcfcf; /* Track color (Chrome, Safari) */
  border-radius: 100px;
}

/* Target the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #8c659c; /* Thumb color (Chrome, Safari) */
  border-radius: 100px;
}

/* Target the scrollbar track */
::-webkit-scrollbar {
  width: 6px; /* Width of the entire scrollbar (Chrome, Safari) */
  height: 6px; /* Height of the entire scrollbar (Chrome, Safari) */
}

.App {
  /* Target the scrollbar track (Firefox) */
  scrollbar-track-color: #cfcfcf;

  /* Target the scrollbar thumb (Firefox) */
  scrollbar-thumb-color: #8c659c;

  /* Target the scrollbar width (Firefox) */
  scrollbar-width: 6px;
  scrollbar-height: 6px;

  /* Target the scrollbar track (Internet Explorer) */
  -ms-overflow-style: none;
}
